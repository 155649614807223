/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

function index() {
  return (
    <div>
      {/* <!-- Iconic Header --> */}
      <nav id="iconic_topbar"></nav>

      {/* <!-- Content here --> */}

      <div className="map-responsive">
        <div className="box_over">
          <b>STORE</b>
          LOCATOR
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1937.784938909056!2d100.55007465660924!3d13.744471127628628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDQ0JzQwLjAiTiAxMDDCsDMzJzA0LjIiRQ!5e0!3m2!1sth!2sth!4v1608881513513!5m2!1sth!2sth"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: " 0" }}
          allowFullScreen
        ></iframe>
      </div>

      <div className="content fix_870">
        <h1>CONTACT US</h1>

        <div className="left_padding">
          <div className="row">
            <div className="col-sm-6 address">
              <div>
                <h2>Iconic Pharmacy</h2>
              </div>
              11/25 Soi Sukhumvit 1<br />
              Khwaeng Khlong Toei Nuea, <br />
              Khet Wattana, Bangkok 10110, Thailand.
              <br />
              <a href="tel:020114530">
                <span className="tel">
                  <i className="bgimg"></i>Tel. 02 011 4530
                </span>
              </a>
            </div>
            <div className="col-sm-6">
              <div className="social_fb col-xs-6">
                <a href="https://www.facebook.com/iconicpharmacy">
                  <i className="bgimg"></i>
                  iconicpharmacy
                </a>
              </div>
              <div className="social_line col-xs-6">
                <a href="https://lin.ee/5LB0yGm">
                  <i className="bgimg"></i>
                  iconicpharmacy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Iconic Footer --> */}
      <nav id="iconic_footer"></nav>
    </div>
  );
}

export default index;
