/* eslint-disable no-script-url */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
function nav() {
  let bumrungrad_data = {
    language: "th",
    language_menu: ["th", "en"],
  };
  function checkMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      console.log("mobile");
      document.querySelector("body, html").classList.add("mobile");
    } else {
      document.querySelector("body, html").classList.remove("mobile");
    }
  }
  function reportWindowSize() {
    console.log("resize");
    checkMobile();
    menu_hide();
  }
  window.addEventListener("resize", reportWindowSize);
  checkMobile();
  document.onreadystatechange = function () {
    if (document.readyState === "complete") {
      //  interface_init();
      bumrungrad_init_data();
      // ----------- Add Event --------------
      document.querySelector(".gototop .goup").addEventListener("click", () => {
        topFunction();
      });
      document.querySelector(".menu_fader").addEventListener("click", () => {
        menu_hide();
      });
      // $(".menu_fader , .menu_logo").mouseover(function () {
      //   menu_hide();
      // });
      document
        .querySelector(".menu_fader , .menu_logo")
        .addEventListener("mouseover", () => {
          menu_hide();
        });
      // $(".menu_super").mouseover(function () {
      //   if (window.innerWidth > 768) {
      //     //menu_hide()
      //   }
      // });
      document
        .querySelector(".menu_super")
        .addEventListener("mouseover", () => {
          if (window.innerWidth > 768) {
            //menu_hide()
          }
        });
      document.querySelector(".menu_language").addEventListener("click", () => {
        document.querySelector(".menu_language_expand").style.display = "block";
        document.querySelector(".menu_fader").style.display = "block";
      });
      // $(".menu_main").click(function () {
      //   if (window.innerWidth < 768) {
      //     menu_list_click(this);
      //   }
      // });
      document.querySelector(".menu_main").addEventListener("click", () => {
        if (window.innerWidth < 768) {
          menu_list_click(this);
        }
      });
      // $(".menu_main").mouseover(function () {
      //   if (window.innerWidth >= 768) {
      //     menu_list_click(this);
      //   }
      // });
      document.querySelector(".menu_main").addEventListener("mouseover", () => {
        if (window.innerWidth >= 768) {
          menu_list_click(this);
        }
      });

      // $(".menu_account_super").click(function () {
      //   console.log(window.innerWidth);
      //   if (window.innerWidth > 768) {
      //     window.location.href = "/" + bumrungrad_data.language + "/account.html";
      //   } else {
      //     account_show();
      //   }
      // });
      document
        .querySelector(".menu_account_super")
        .addEventListener("click", () => {
          console.log(window.innerWidth);
          if (window.innerWidth > 768) {
            window.location.href =
              "/" + bumrungrad_data.language + "/account.html";
          } else {
            account_show();
          }
        });
      //  Lock Menu
      try {
        if (bumrungrad_data) {
          if (bumrungrad_data.menu) {
            // $("." + bumrungrad_data.menu).addClass("selected_fix");
            console.log("menu ");
            document
              .querySelector("." + bumrungrad_data.menu)
              .classList.add("selected_fix");
          }
          if (bumrungrad_data.account === "active") {
            document
              .querySelector(".menu_account")
              .classList.add("active");
          }
        }
      } catch (e) {}
    }
  };
  function bumrungrad_init_data() {
    if (bumrungrad_data) {
      // Language
      if (bumrungrad_data.language_menu) {
        let language = "";
        let pathname = window.location.href.split(
          "/" + bumrungrad_data.language + "/"
        );
        pathname = pathname[1];
        // $.loadScript(
        //   "/js/popup_" + bumrungrad_data.language + ".js",
        //   function () {}
        // );
        language =
          '<span class="lang_EN" onclick="location.href=\'/en/' +
          pathname +
          "';\">EN - English</span>" +
          //+'<span class="lang_AR" onclick="location.href=\'/ar/'+pathname+'\';">AR - العربية</span>'
          //+'<span class="lang_MM" onclick="location.href=\'/mm/'+pathname+'\';">MM - မြန်မာ</span>'
          //+'<span class="lang_ID" onclick="location.href=\'/id/'+pathname+'\';">ID - Bahasa</span>'
          //+'<span class="lang_JP" onclick="location.href=\'/jp/'+pathname+'\';">JP - 日本語</span>'
          '<span class="lang_TH" onclick="location.href=\'/th/' +
          pathname +
          "';\">TH - ไทย</span>";
        //+'<span class="lang_ZH" onclick="location.href=\'/zh/'+pathname+'\';">ZH - 中文(简体)</span>';
        let element = document.querySelector(".menu_language");
        element.innerHTML =
          bumrungrad_data.language.toUpperCase() + "<span></span>";
        element.className += " show";
        let menuLanguage = document.querySelector(".menu_language_expand");
        menuLanguage.innerHTML = language;
        document.getElementsByClassName(
          "lang_" + bumrungrad_data.language.toUpperCase()
        )[0].style.display = "none";
      }

      let head = document.getElementsByTagName("head")[0];
      let link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = "/fonts/" + bumrungrad_data.language.toUpperCase() + ".css";
      head.appendChild(link);
    } else {
      console.log("please config bumrungrad_data");
    }
  }
  function menu_ic_close_click(x) {
    x.classList.toggle("show");
    if (x.classList.contains("show")) {
      menu_show();
    } else {
      menu_hide();
    }
  }
  function menu_hide() {
    console.log("menu hide");
    document
      .querySelector("#iconic_topbar .menu_list")
      .classList.remove("show");
    document.querySelector("#iconic_topbar .menu_fader").style.display = "none";
    document
      .querySelector("#iconic_topbar .menu_ic_close")
      .classList.remove("show");
    document.querySelector(".menu_main").classList.remove("selected");
    document.querySelector(".menu_sub").style.display = "none";
    // account sub menu on mobile
    document.querySelector(".menu_account_sub").classList.remove("selected");
    document.querySelector("body, html").classList.remove("fix");
    // $(".menu_language_expand").hide();
    document.querySelector(".menu_language_expand").style.display = "none";
  }

  function menu_show() {
    // clear account sub menu */
    console.log("clear account sub menu");
    document.querySelector(".menu_account_sub").classList.remove("selected");
    console.log("show menu");
    document.querySelector("#iconic_topbar .menu_list").classList.add("show");
    document.querySelector("#iconic_topbar .menu_list").style.display = "block";
  }

  function menu_list_click(x) {
    if (x.classList.contains("selected")) {
      x.classList.toggle("selected");
      document.querySelector(".menu_sub").style.display = "none";
    } else {
      document.querySelector(".menu_main").classList.remove("selected");
      document.querySelector(".menu_sub").style.display = "none";
      document.querySelector(".menu_language_expand").style.display = "none";
      document.querySelector("#iconic_topbar .menu_fader").style.display =
        "block";
      x.classList.toggle("selected");
      if (x.classList.contains("m1")) {
        document.querySelector(".menu_sub.m1").style.display = "block";
      } else if (x.classList.contains("m2")) {
        console.log("menu_list_click");
        document.querySelector(".menu_sub.m2").style.display = "block";
      } else if (x.classList.contains("m3")) {
        console.log("menu_list_click");
        document.querySelector(".menu_sub.m3").style.display = "block";
      } else if (x.classList.contains("m4")) {
        console.log("menu_list_click");
        document.querySelector(".menu_sub.mภ").style.display = "block";
      } else if (x.classList.contains("m5")) {
      }
    }
  }
  function account_show() {
    console.log("shoaccount_showw");
    if (
      document.querySelector(".menu_account_sub").classList.contains("selected")
    ) {
      menu_hide();
    } else {
      menu_hide();
      console.log("account_show ");
      document.querySelector(".menu_account_sub").classList.add("selected");
      document.querySelector("#iconic_topbar .menu_fader").style.display =
        "block";
      document.querySelector("body, html").classList.add("fix");
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      document.querySelector(".gototop .goup").style.display = "block";
    } else {
      document.querySelector(".gototop .goup").style.display = "none";
    }
  }
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  // $(function () {
  //   $.smartbanner();
  //   $.smartbanner({
  //     daysHidden: 15, // Duration to hide the banner after being closed (0 = always show banner / 15 default)
  //     daysReminder: 90, // Duration to hide the banner after "VIEW" is clicked *separate from when the close button is clicked* (0 = always show banner / 90 default)
  //   });
  //   //  Match Height */
  //   //$('.products_thumb').matchHeight();
  //   setTimeout(function () {
  //     $(".thumb_content").matchHeight();
  //     $(".tab_article").matchHeight();
  //   }, 200);
  // });

  // $.loadScript = function (url, callback) {
  //   $.ajax({
  //     url: url,
  //     dataType: "script",
  //     success: callback,
  //     async: true,
  //   });
  // };
  return (
    <nav id="iconic_topbar">
      <div className="gototop">
        <div className="goup">
          <img src="/images/ic_dropdown_g_over.png" />
        </div>
        <div className="chat">
          <img src="/images/ic_fbchat.png" />
        </div>
      </div>
      <div className="popup">
        <div className="popup_fader" />
        <div id="popup_container" />
      </div>
      <div className="menu_fader" />
      <div className="menu_bg" onClick={() => menu_hide()} />
      <div
        className="menu_ic_close"
        onClick={() =>
          menu_ic_close_click(document.querySelector(".menu_ic_close"))
        }
      >
        <div className="bar1" />
        <div className="bar2" />
        <div className="bar3" />
      </div>
      <div className="menu_list">
        <a className="m1 menu_main">
          HEALTH &amp; ADVICE
          <i />
        </a>
        <div className="m1 menu_sub">
          <span>
            <a href="/th/article.html?tab=hot-issues">Hot Issues</a>
            <a href="/th/article.html?tab=health">Health Articles</a>
            <a href="/th/article.html?tab=weight-control-exercise">
              Weight Control &amp; Exercise
            </a>
            <a href="/th/article.html?tab=birth-control">Birth Control</a>
            <a href="/th/article.html?tab=beauty-health-skincare">
              Beauty Health
              <br className="show_pc" />
              And Skincare
            </a>
            <a href="/th/article.html?tab=vitamin">
              Vitamins &amp; Supplements
              <br />
              For Each Age
            </a>
          </span>
        </div>
        <a
          className="m2 menu_main"
          href="https://store.iconicpharmacy.com/th/products"
        >
          OUR PRODUCTS
          <i />
        </a>
        <a className="m3 menu_main" href="https://lin.ee/5LB0yGm">
          PHARMACY SERVICE
          <i />
        </a>
        <a className="m5 menu_main" href="/th/contactus.html">
          CONTACT US
        </a>
      </div>
      <div className="menu_super">
        <div className="menu_language" />
        <div className="menu_language_expand">
          <span className="lang_EN">EN - English</span>
          <span className="lang_TH">TH - ไทย</span>
        </div>
        <div className="menu_account_super" />
      </div>
      <a href="/">
        <div className="menu_logo" />
      </a>
      <div className="menu_account_sub">
        <div className="menu_ic_close" onClick={() => menu_hide()} />
        <h2>ACCOUNT SETTING</h2>
        <span>
          <a href="/th/account.html">My Account</a>
        </span>
        <span>
          <a href="/th/account_address.html">My Address</a>
        </span>
        <span>
          <a href="/th/account_password.html">Change Password</a>
        </span>
        <span>
          <a href="#">Bank Accounts/Cards</a>
        </span>
        <span>
          <a href="#">My Purchases</a>
        </span>
        <span>
          <a href="#">My Product Reviews</a>
        </span>
        <span>
          <a href="#">My Wishlist</a>
        </span>
        {/* <span>
          <a href="javascript:popup_show_signout()">Logout</a>
        </span> */}
      </div>
    </nav>
  );
}

export default nav;
