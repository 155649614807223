/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

function banner() {
  const swipper = {
    slidesPerView: 1.1,
    spaceBetween: 0,
    loop: false,
    freeMode: true,
    touchRatio: 1,
    breakpoints: {
      480: {
        slidesPerView: 2.1,
        spaceBetween: 0,
        touchRatio: 1,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 0,
        touchRatio: 0,
      },
    },
  };
  return (
    <div
      id="banner_sub"
      className="content swiper-banner_sub swiper-container swiper-style"
    >
      <div className="swiper-wrapper">
        <Swiper
          slidesperview={swipper.slidesPerView}
          spaceBetween={swipper.spaceBetween}
          loop={swipper.loop}
          touchRatio={swipper.touchRatio}
          breakpoints={swipper.breakpoints}
          className="swiper-slide"
        >
          <SwiperSlide className="swiper-slide s1">
            <Link to="article.html">
              <img src="../images/banner_sub/health_advice_594x250.jpg" />
              RESOURCE FOR ARTICLES AND LIFESTYLE
            </Link>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide s2">
            <Link to="#">
              <img src="../images/banner_sub/pharmacy_service_594x250.jpg" />
              ASK ABOUT OUR TREATMENTS AND SERVICES
            </Link>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide s3">
            <Link to="#">
              <img src="../images/banner_sub/member_service_594x250.jpg" />
              MEMBERSHIP FOR MORE PRIVILEGES
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default banner;
