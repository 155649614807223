/* eslint-disable no-undef */
import React from "react";

function swiperWrapper() {
  const article_click_tab = (target) => {
    console.log("article_click_tab(" + target + ")");
    $(".tab_article").removeClass("selected");
    $(".tab_article." + target).addClass("selected");

    $(".article_thumb").hide();
    $(".article_thumb." + target).show();
  };
  return (
    <div className="content article">
      <div
        id="tab_article"
        className="swiper-article swiper-container swiper-style"
      >
        <div className="swiper-wrapper">
          <div
            className="swiper-slide tab_article hot-issues selected"
            onClick={() => article_click_tab("hot-issues")}
          >
            HOT ISSUES
          </div>
          <div
            className="swiper-slide tab_article health"
            onClick={() => article_click_tab("health")}
          >
            HEALTH
          </div>
          <div
            className="swiper-slide tab_article weight-control-exercise"
            onClick={() => article_click_tab("weight-control-exercise")}
          >
            Weight control
            <br />& Exercise
          </div>
          <div
            className="swiper-slide tab_article birth-control"
            onClick={() => article_click_tab("birth-control")}
          >
            Birth control
          </div>
          <div
            className="swiper-slide tab_article beauty-health-skincare"
            onClick={() => article_click_tab("beauty-health-skincare")}
          >
            Beauty health
            <br />
            and skincare
          </div>
          <div
            className="swiper-slide tab_article vitamin"
            onClick={() => article_click_tab("vitamin")}
          >
            Vitamins & supplements
            <br />
            for each age
          </div>
        </div>
      </div>
    </div>
  );
}

export default swiperWrapper;
