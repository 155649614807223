import React from "react";
import Swiper from "./swiper";
import SwiperWrapper from "./swiper-wrapper";
import Article from "./article";
import Nav from "../config/nav";
import Footer from "../config/footer";

function index() {
  return (
    <>
      {/* <!-- Iconic Header --> */}
      <Nav/>
      {/* <!-- Content here --> */}
      <Swiper />
      <SwiperWrapper />
      <Article />
      {/* <!-- Iconic Footer --> */}
      <Footer/>
    </>
  );
}

export default index;
