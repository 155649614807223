/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Link } from "react-router-dom";


function swiper() {
  return (
    <div id="highlight" className="swiper-highlight swiper-container swiper-style">
      <div className="swiper-wrapper">
        <div className="swiper-slide s1">
          <Link to="#"
            ><img src="../images/article/highlight1.jpg" className="show_pc" /><img
              src="../images/article/highlight1_1040x1040.jpg"
              className="show_mobile"
          /></Link>
        </div>
        <div className="swiper-slide s2">
          <Link to="#"
            ><img src="../images/article/highlight1.jpg" className="show_pc" /><img
              src="../images/article/highlight1_1040x1040.jpg"
              className="show_mobile"
          /></Link>
        </div>
        <div className="swiper-slide s3">
          <Link to="#"
            ><img src="../images/article/highlight1.jpg" className="show_pc" /><img
              src="../images/article/highlight1_1040x1040.jpg"
              className="show_mobile"
          /></Link>
        </div>
      </div>
      {/* <!-- Add Pagination --> */}
      <div className="swiper-pagination"></div>
      {/* <!-- Add Arrows --> */}
      <div className="show_pc">
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
      </div>
    </div>
  )
}

export default swiper