import React from "react";
import Swipers from "./swiper";
import Banner from "./banner";
// import Product from "./product";
// import Recommend from "./recommend";
// import Categories from "./category";
// import Brand from "./brand";
import Nav from "../config/nav";
import Footer from "../config/footer";

function index() {
  
  return (
    <>
      {/* <!-- Iconic Header --> */}
      <Nav />
      {/* <!-- Content here --> */}
      <Swipers/>
      <div className="break_mobile"></div>
      <Banner/>
      <div className="break_mobile"></div>
      {/* <Product/> */}
      <div className="break_mobile"></div>
      {/* <Recommend/> */}
      <div className="break_mobile"></div>
      {/* <Categories/> */}
      <div className="break_mobile"></div>
      {/* <Brand/> */}
      {/* <!-- Iconic Footer --> */}
      <Footer/>
      {/* <nav id="iconic_footer"></nav> */}
    </>
  );
}

export default index;
