/* eslint-disable jsx-a11y/alt-text */
import React from "react";

function article() {
  return (
    <div id="article" className="relate article content_article tab_menu1">
      <div className="row article-feed">
        <div className="thumb article_thumb col-xs-6 hot-issues">
          <div className="thumb_content_holder">
            <a href="article/acne/">
              <img src="article/acne/acne_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Hot Issues</b>
                จัดการปัญหาสิวให้อยู่หมัด
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 health">
          <div className="thumb_content_holder">
            <a href="article/baby_blue/">
              <img src="article/baby_blue/babyblue_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Health</b>
                เรื่องเศร้า ๆ ของคุณแม่หลังคลอด
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 health">
          <div className="thumb_content_holder">
            <a href="article/leukorrhea/">
              <img src="article/leukorrhea/leukorrhea_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Health</b>
                ตกขาว เรื่องที่ผู้หญิงเราควรรู้
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 health">
          <div className="thumb_content_holder">
            <a href="article/looking_good/">
              <img src="article/looking_good/lookinggood_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Health</b>
                Looking Good, Feeling Good from the Outside In
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 weight-control-exercise">
          <div className="thumb_content_holder">
            <a href="article/acne/">
              <img src="article/acne/acne_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Weight Control & Exercise</b>
                จัดการปัญหาสิวให้อยู่หมัด
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 birth-control">
          <div className="thumb_content_holder">
            <a href="article/acne/">
              <img src="article/acne/acne_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Birth Control</b>
                จัดการปัญหาสิวให้อยู่หมัด
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 beauty-health-skincare">
          <div className="thumb_content_holder">
            <a href="article/looking_good/">
              <img src="article/looking_good/lookinggood_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Beauty Health and Skincare</b>
                Looking Good, Feeling Good from the Outside In
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 health">
          <div className="thumb_content_holder">
            <a href="article/vit_B12/">
              <img src="article/vit_B12/vitaminB12_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Health</b>
                รู้หรือไม่...การขาดวิตามินบี 12 ทำให้เกิดผลเสียต่อสุขภาพอย่างไร
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="thumb article_thumb col-xs-6 vitamin">
          <div className="thumb_content_holder">
            <a href="article/vit_B12/">
              <img src="article/vit_B12/vitaminB12_highlight_mobile.jpg" />
              <div className="thumb_content">
                <b>Vitamins & Supplements For Each Age</b>
                รู้หรือไม่...การขาดวิตามินบี 12 ทำให้เกิดผลเสียต่อสุขภาพอย่างไร
                <span>
                  READ MORE <i></i>
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default article;
