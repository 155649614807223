/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./index.css";
// import Swiper from "swiper";
function swiper() {
  document.onreadystatechange = function () {
    if (document.readyState === "complete") {
      // swiper_highlight();
    }
  };
  //  let swipper;
  //    function swiper_highlight(){
  //     new Swiper('.swiper-highlight', {
  //        slidesPerView: 1,
  //        spaceBetween: 0,
  //        loop: true,
  //       //autoHeight: true,
  //        autoplay: {
  //          delay: 5000,
  //          disableOnInteraction: false,
  //        },
  //        pagination: {
  //          el: '.swiper-pagination',
  //          clickable: true,
  //        },
  //        navigation: {
  //          nextEl: '.swiper-button-next',
  //          prevEl: '.swiper-button-prev',
  //        },
  //       debugger: true,
  //      });
  //   }
  const swipper = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    //autoHeight: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    debugger: "true",
  };
  return (
    //   <div id="highlight" className="swiper-highlight swiper-container swiper-style">
    // 	<div className="swiper-wrapper">
    // 		<div className="swiper-slide s1"><a href="#"><img src="../images/highlight/highlight_1600x500_1.jpg" className="show_pc" /><img src="../images/highlight/highlight_1040x1040_1.jpg" className="show_mobile" /></a></div>
    // 		<div className="swiper-slide s2"><a href="#"><img src="../images/highlight/highlight_1600x500_2.jpg" className="show_pc" /><img src="../images/highlight/highlight_1040x1040_2.jpg" className="show_mobile" /></a></div>
    // 		<div className="swiper-slide s3"><a href="#"><img src="../images/highlight/highlight_1600x500_3.jpg" className="show_pc" /><img src="../images/highlight/highlight_1040x1040_3.jpg" className="show_mobile" /></a></div>
    // 		<div className="swiper-slide s4"><a href="#"><img src="../images/highlight/highlight_1600x500_4.jpg" className="show_pc" /><img src="../images/highlight/highlight_1040x1040_4.jpg" className="show_mobile" /></a></div>
    // 	</div>
    // 	{/* <!-- Add Pagination --> */}
    // 	<div className="swiper-pagination"></div>
    // 	{/* <!-- Add Arrows --> */}
    //   <div className="show_pc">
    // 		<div className="swiper-button-next"></div>
    // 		<div className="swiper-button-prev"></div>
    //   </div>
    // </div>
    <div
      id="highlight"
      className="swiper-highlight swiper-container swiper-style"
    >
      <Swiper
        className="swiper-wrapper"
        modules={[Autoplay, Navigation, Pagination, FreeMode]}
        slidesPerView={swipper.slidesPerView}
        spaceBetween={swipper.spaceBetween}
        loop={swipper.loop}
        pagination={swipper.pagination}
        navigation={swipper.navigation}
        autoplay={swipper.autoplay}
        debugger={swipper.debugger}
      >
        <SwiperSlide className="swiper-slide s1">
          <Link to="#">
            <img
              src="../images/highlight/highlight_1600x500_1.jpg"
              className="show_pc"
            />
            <img
              src="../images/highlight/highlight_1040x1040_1.jpg"
              className="show_mobile"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide s2">
          <Link to="#">
            <img
              src="../images/highlight/highlight_1600x500_2.jpg"
              className="show_pc"
            />
            <img
              src="../images/highlight/highlight_1040x1040_2.jpg"
              className="show_mobile"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide s3">
          <Link to="#">
            <img
              src="../images/highlight/highlight_1600x500_3.jpg"
              className="show_pc"
            />
            <img
              src="../images/highlight/highlight_1040x1040_3.jpg"
              className="show_mobile"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide s4">
          <Link to="#">
            <img
              src="../images/highlight/highlight_1600x500_4.jpg"
              className="show_pc"
            />
            <img
              src="../images/highlight/highlight_1040x1040_4.jpg"
              className="show_mobile"
            />
          </Link>
        </SwiperSlide>
      </Swiper>
      {/* <!-- Add Pagination --> */}
      <div
        className="swiper-pagination"
        style={{ textAlign: "center !important" }}
      />
      {/* <!-- Add Arrows --> */}
      <div className="show_pc">
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
      </div>
    </div>
  );
}

export default swiper;
