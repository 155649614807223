import Index from "./component/index/index"
import { Outlet } from "react-router-dom";


function App() {
  return (
    <>
      <Index/>
      <Outlet />
    </>
  );
}

export default App;
