/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import $ from "jquery";


function footer() {
    function menu_footer_click(x) {
        if($(x).hasClass( "selected" )){
        }else{
          $("#iconic_footer .tab").removeClass( "selected" );
          $(".iconic_footer_content").hide();
          x.classList.toggle("selected");
          if($(x).hasClass( "footer_tab1" )){
            $(".iconic_footer_content.default").show();
          }else if($(x).hasClass( "footer_tab2" )){
            $(".iconic_footer_content.sitemap").show();
          }
        }
      }
  return (
    <nav id="iconic_footer">
      <div className="tab_content">
        <div className="tab_content_fix">
          <div
            className="tab footer_tab1 selected"
            onClick={() => menu_footer_click(this)}
          >
            <img src="/images/iconicpharmacy_b.png" />
          </div>
          <div className="tab footer_tab2" onClick={() => menu_footer_click(this)}>
            SITE MAP
          </div>
          <div className="share">
            <b>FOLLOW US</b>
            <a className="fb" href="https://m.me/iconicpharmacy/" />
            <a className="line" href="https://lin.ee/5LB0yGm" />
          </div>
        </div>
      </div>
      <div className="default iconic_footer_content">
        <div className="enews">
          <b>ADD MORE HEALTH TO YOUR EMAIL</b>
          <input className="email" placeholder="Your Email" />
          <span>OK</span>
        </div>
        <div className="social">
          <b>STAY IN TOUCH WITH ICONIC</b>
          <span>Pharmacy Store by Pharmacists Open today until 8:00 PM</span>
          <div>
            <a href="tel:+66-5524-1984">
              <img src="/images/ic_whatsapp.png" />
              <i>WhatsApp</i>
            </a>
          </div>
          <div>
            <a href="https://m.me/iconicpharmacy/">
              <img src="/images/ic_fbchat.png" />
              <i>
                Facebook
                <br />
                Messenger
              </i>
            </a>
          </div>
          <div>
            <a href="tel:+66-2011-4530">
              <img src="/images/ic_tel.png" />
              <i>(02) 011 4530</i>
            </a>
          </div>
          <div>
            <a>
              <img src="/images/ic_email.png" />
              <i>
                Send
                <br className="br_480" /> an e-mail
              </i>
            </a>
          </div>
        </div>
      </div>
      <div className="sitemap iconic_footer_content">
        <div className="column_mobile">
          <div className="column md">
            <a className="m1 menu_footer no_point">
              HEALTH &amp; ADVICE
              <i />
            </a>
            <span>
              <a href="/th/article.html?tab=hot-issues">Hot Issues</a>
              <a href="/th/article.html?tab=health">Health Articles</a>
              <a href="/th/article.html?tab=weight-control-exercise">
                Weight Control &amp; Exercise
              </a>
              <a href="/th/article.html?tab=birth-control">
                Birth Control
              </a>
              <a href="/th/article.html?tab=beauty-health-skincare">
                Beauty Health
                <br className="show_pc" />
                And Skincare
              </a>
              <a href="/th/article.html?tab=vitamin">
                Vitamins &amp; Supplements
                <br />
                For Each Age
              </a>
            </span>
          </div>
          <div className="column lg">
            <a className="m2 menu_footer" href="/th/products.html">
              OUR PRODUCTS
              <i />
            </a>
            <span>
              <a href="/th/products.html">Electricals Device</a>
              <a href="/th/products.html">
                Mobility Aids &amp; Daily Living
              </a>
              <a href="/th/products.html">Health &amp; Wellbeing</a>
              <a href="/th/products.html">
                First Aid Medicines &amp; Treatments
              </a>
              <a href="/th/products.html">Mother &amp; Baby</a>
              <a href="/th/products.html#">Women Health</a>
              <a href="/th/products.html">Men Health</a>
              <a href="/th/products.html">Skincare</a>
              <a href="/th/products.html">Vitamins &amp; Supplements</a>
              <a href="/th/products.html">Weight Management</a>
              <a href="/th/products.html">Herbal Products</a>
              <a href="/th/products.html">Medical Food</a>
              <a href="/th/products.html">Promotions &amp; Sample</a>
            </span>
          </div>
        </div>
        <div className="column_mobile">
          <div className="column lg">
            <a className="m3 menu_footer" href="https://lin.ee/5LB0yGm">
              PHARMACY SERVICE
              <i />
            </a>
            <span>
              <a href="#">Drug Information Service</a>
              <a href="#">Vitamins &amp; Supplements For Each Age</a>
              <a href="#">Birth Control</a>
              <a href="#">Weight Control</a>'<a href="#">Vaccination</a>
              <a href="#">Stop Smoking</a>
              <a href="#">Beauty Health And Skincare</a>
              <a href="#">Check Up And Lab Result</a>
              <a href="#">Pricing</a>
              <a href="#">Telepharmacy</a>
            </span>
          </div>
          <div className="column">
            <a className="m4 menu_footer" href="https://lin.ee/5LB0yGm">
              MEMBER SERVICE
              <i />
            </a>
            <span>
              <a href="#">How To Earn Points</a>
              <a href="#">Member's Points</a>
              <a href="#">Redeem</a>
              <a href="#">Service History</a>
              <a href="#">Reorder</a>
              <a href="#">Store Pickup</a>
              <a href="#">Shipping And Follow Up</a>
            </span>
          </div>
          <div className="column">
            <a className="m5 menu_footer" href="/th/contactus.html">
              CONTACT US
              <i />
            </a>
            <span />
          </div>
        </div>
      </div>
      <div className="link">
        <a href="/th/terms.html">
          TERMS <span>&amp; CONDITIONS</span>
        </a>
        <a href="/th/privacy.html">
          PRIVACY <span>POLICY</span>
        </a>
        <a href="/th/cookies.html">
          COOKIES <span>POLICY</span>
        </a>
      </div>
      <div className="copy">
        <a href="https://www.bumrungrad.com/th" target="_blank">
          <img src="/images/bumrungrad.png" />
        </a>
        <b>© 2021 Iconic Pharmacy, All Rights reserved.</b>
      </div>
    </nav>
  );
}

export default footer;
